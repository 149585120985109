import axios from "axios";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

document.addEventListener("DOMContentLoaded", () => {
  const banner = document.querySelector(".bn-site-banner");
  const bannerId = banner?.getAttribute("data-banner-id");
  const dismiss = document.querySelector(".bn-dismiss");
  if (banner && bannerId && dismiss) {
    dismiss.addEventListener("click", () => {
      banner.classList.add("bn-hide");
      axios
        .post("/api/v1/site-banner-dismiss", {
          bannerId,
        })
        .catch(() => {
          // Keep this for a while, but we should probably remove it.
          // eslint-disable-next-line no-console
          console.error("Failed to dismiss banner.");
        });
    });
  }
});
